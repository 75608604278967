<template lang="pug">
    div(v-if="getLoggedIn" class="mr-2" style="overflow:scroll; border:0px solid red;")

        div(class="mb-4" style="position:relative; border:0px solid;")
            v-btn(@click="openInsertReportPopup()" height="25" elevation="0" style="font-family:Poppins; font-size:15px; text-transform:none; background-color:#EBEBEB; color:#27407E; border:1px solid #27407E;")
                v-icon mdi-cog-outline
                span(class="ml-3") Add Report

            //- Popup window insert
            div(v-if="insertReportActive" class="pl-2 pr-2 pt-1 pb-2" style="position:absolute; top:40px; font-family:Courier; font-size:12px; background-color:#ebebeb; z-index:99999; box-shadow:4px 4px 8px 1px rgba(0, 0, 0, 0.15); border:0px solid lightgrey;")
                div(style="display:table; width:690px;")
                    div(style="display:table-cell; position:relative; border:0px solid;")
                        div(style="display:table; height:25px;")
                            div(style="display:table-cell; position:relative; width:180px; border:0px solid;")
                                div(style="position:absolute;") Period
                            div(style="display:table-cell; width:180px; border:0px solid;")
                                div(style="position:relative; border:0px solid;")
                                    v-select(
                                        item-text="text"
                                        item-value="text"
                                        v-model="selectedPeriod"
                                        :items="selectablePeriods"
                                        solo
                                        flat
                                        dense
                                        outlined
                                        style="position:absolute; font-family:Courier; font-size:12px; top:0; left:0; width:160px; border-radius:0;"
                                    )
                        div(class="mt-2" style="display:table; height:25px;")
                            div(style="display:table-cell; position:relative; width:180px; border:0px solid;")
                                div(style="display:table-cell; width:180px; border:0px solid;") Report Date
                            div(style="display:table-cell; position:relative; width:180px; border:0px solid;")
                                div(style="position:absolute; border:0px solid;")
                                    input(type="date" v-model="selectedDate" class="pl-1 pr-1" style="background-color:#FFFFFF; height:20px; width:160px; border:1px solid #A8A8A8;")
                        div(class="mt-2" style="display:table; height:25px;")
                            div(style="display:table-cell; position:relative; width:180px; border:0px solid;")
                                div(style="position:absolute; border:0px solid;") Upload PDF
                            div(style="display:table-cell; border:0px solid;")
                                div(style="height:20px; border:0px solid;")
                                    div(style="position:relative; height:20px; width:500px; border:0px solid;")
                                        v-btn(@click="uploadFile()" :outlined="true" height="20" width="93" style="position:absolute; top:0; left:0; font-family:Poppins; font-size:12px; color:#28407E; text-transform:none; border:1px solid #28407E;") File
                                        input(@change="getFileData($event)" type="file" name="image" style="display:none;" ref="fileUploadElement")
                                        //- TODO: Will add this later on for file upload clarification
                                        //- div(v-if="uploadedBase64EncodedFile !== ''" style="position:absolute; left:100px; border:0px solid;") Something happened...
                        div(class="mt-2" style="display:table; height:25px;")
                            div(style="display:table-cell; position:relative; width:180px; border:0px solid;")
                                div(style="position:absolute; border:0px solid;") Upload Slides
                            div(style="display:table-cell; border:0px solid;")
                                div(style="height:20px; border:0px solid;")
                                    div(style="position:relative; height:20px; width:500px; border:0px solid;")
                                        v-btn(@click="uploadFile2()" :outlined="true" height="20" width="93" style="position:absolute; top:0; left:0; font-family:Poppins; font-size:12px; color:#28407E; text-transform:none; border:1px solid #28407E;") File
                                        input(@change="getFileData2($event)" type="file" name="image" style="display:none;" ref="slideUploadElement")
                                        //- TODO: Will add this later on for file upload clarification
                                        //- div(v-if="uploadedBase64EncodedFile2 !== ''" style="position:absolute; left:100px; border:0px solid;") Something happened...
                        div(class="mt-2" style="display:table; height:50px;")
                            div(style="display:table-cell; position:relative; width:180px; border:0px solid;")
                                div(style="position:absolute; border:0px solid;") Video link
                            div(style="display:table-cell;")
                                input(type="text" v-model="videoLink" style="background-color:#FFFFFF; width:500px; border:1px solid #A8A8A8;")
                        div(@click="cancel()" style="position:absolute; top:0; right:-20px; font-family:Poppins; font-size:12px; width:80px; color:#CF8631; cursor:pointer; border:0px solid;") Cancel
                        div(style="position:absolute; bottom:0; right:0; border:0px solid;")
                            v-btn(@click="saveFile()" width="80" height="20" class="mr-2" style="font-family:Poppins; font-size:12px; background-color:#28407E; color:#FFFFFF; text-transform:none; border:1px solid #FB993F;") Save

            //- Popup window update
            div(v-if="updateReportActive" class="pl-2 pr-2 pt-1 pb-2" style="position:absolute; top:40px; font-family:Courier; font-size:12px; background-color:#ebebeb; z-index:99999; box-shadow:4px 4px 8px 1px rgba(0, 0, 0, 0.15); border:0px solid lightgrey;")
                div(style="display:table; width:690px;")
                    div(style="display:table-cell; position:relative; border:0px solid;")
                        div(class="mt-2" style="display:table; height:25px;")
                            div(style="display:table-cell; position:relative; width:180px; border:0px solid;")
                                div(style="position:absolute; border:0px solid;") Upload Slides
                            div(style="display:table-cell; border:0px solid;")
                                div(style="height:20px; border:0px solid;")
                                    div(style="position:relative; height:20px; width:135px; border:0px solid;")
                                        v-btn(@click="uploadSlideUpdate()" :outlined="true" height="20" width="93" style="position:absolute; top:0; left:0; font-family:Poppins; font-size:12px; color:#28407E; text-transform:none; border:1px solid #28407E;") File
                                        input(@change="getFileDataSlideUpdate($event)" type="file" name="image" style="display:none;" ref="slideUploadUpdateElement")
                        div(class="mt-2" style="display:table; height:50px;")
                            div(style="display:table-cell; position:relative; width:180px; border:0px solid;")
                                div(style="position:absolute; border:0px solid;") Video link
                            div(style="display:table-cell;")
                                input(type="text" v-model="reportUpdate.video_link" style="background-color:#FFFFFF; width:500px; border:1px solid #A8A8A8;")
                        div(@click="cancel2()" style="position:absolute; top:0; right:-20px; font-family:Poppins; font-size:12px; width:80px; color:#CF8631; cursor:pointer; border:0px solid;") Cancel
                        div(style="position:absolute; bottom:0; right:0; border:0px solid;")
                            v-btn(@click="updateFile()" width="80" height="20" class="mr-2" :disabled="uploadedBase64EncodedFilePdfUpdate === '' && uploadedBase64EncodedFileSlideUpdate === '' && reportUpdateString === reportUpdateCopyString" :style="(uploadedBase64EncodedFilePdfUpdate === '' && uploadedBase64EncodedFileSlideUpdate === '' && reportUpdateString === reportUpdateCopyString) ? 'border:1px solid grey' : ''" style="font-family:Poppins; font-size:12px; background-color:#28407E; color:#FFFFFF; text-transform:none; border:1px solid #FB993F;") Save

        div(style="width:852px; height:calc(100vh - 160px); overflow-y:scroll; color:#474747; border:0px solid;")
            div(style="font-family:'Courier New'; font-weight:bold; font-size:12px; border:0px solid;")
                table(cellpadding="0" cellspacing="0" style="border:0px solid;")

                    //- head block
                    tr(style="line-height:14px; height:27px; vertical-align:top;")
                        td(style="width:300px; border:0px solid;") Title
                        td(style="width:120px; border:0px solid;") Report Date
                        td(style="width:70px; border:0px solid;") Report
                        td(style="width:70px; border:0px solid;") Slides
                        td(style="width:70px; border:0px solid;") Video
                        td(style="width:50px; border:0px solid;")
                        td(style="width:170px; border:0px solid;") PDF Source

                    //- main block
                    tr(v-for="report in reports" style="font-family:Courier; font-size:12px; font-weight:400; line-height:14px; height:28px; vertical-align:top; border:0px solid;")
                        td(style="width:300px; border:0px solid;") {{ getTypeText(report) }}
                        td(style="width:120px; border:0px solid;") {{ report.date_report }}
                        td(style="width:70px; border:0px solid;")
                            a(v-if="report.pdf_link_local !== undefined && report.pdf_link_local !== '' && report.pdf_link_local !== null" :href="`${hostAddress}/${getCompany.short_name}/${report.pdf_link_local}`" target="_blank")
                                img(src="@/assets/pdf-icon.png" style="height:16px;")

                            a(v-else-if="report.pdf_link !== undefined && report.pdf_link !== '' && report.pdf_link !== null" :href="`https://documents.millistream.com/${report.pdf_link}`" target="_blank")
                                img(src="@/assets/pdf-icon.png" style="height:16px;")

                        td(style="width:70px; border:0px solid;")
                            a(v-if="report.slides_link_local !== undefined && report.slides_link_local !== '' && report.slides_link_local !== null" :href="`${hostAddress}/${getCompany.short_name}/${report.slides_link_local}`" target="_blank")
                                img(src="@/assets/slides-icon.png" style="height:16px;")
                        td(style="width:70px; border:0px solid;")
                            a(v-if="report.video_link !== undefined && report.video_link !== '' && report.video_link !== null" :href="report.video_link" target="_blank")
                                img(src="@/assets/media-play-icon.svg" style="height:16px;")
                        td(style="width:50px; border:0px solid;")
                            img(v-if="report.pdf_link_local !== undefined" @click="openInsertReportPopup2(report)" src="@/assets/edit-kpi-icon.svg" style="height:16px; cursor:pointer;")
                        td(style="width:170px; border:0px solid;")
                            span(v-if="report.pdf_link_local !== undefined") PDF uploaded
                            span(v-else) PDF from Millistream
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";
import _ from "lodash";

export default {
    name: "ReportsAndMedia",
    data() {
        return {
            insertReportActive: false,
            updateReportActive: false,
            companies: [],
            companiesCopy: [],
            type: [
                {
                    type: 'FY',
                    text: 'Full Year Report',
                    typeText: 'Full Year',
                },
                {
                    type: 'Q',
                    text: 'Interim Report',
                    typeText: 'Interim',
                }
            ],
            selectablePeriods: [
                {
                    text: '2018-Q1',
                },
                {
                    text: '2018-Q2',
                },
                {
                    text: '2018-Q3',
                },
                {
                    text: '2018-Q4',
                },
                {
                    text: '2018',
                },
                {
                    text: '2019-Q1',
                },
                {
                    text: '2019-Q2',
                },
                {
                    text: '2019-Q3',
                },
                {
                    text: '2019-Q4',
                },
                {
                    text: '2019',
                },
                {
                    text: '2020-Q1',
                },
                {
                    text: '2020-Q2',
                },
                {
                    text: '2020-Q3',
                },
                {
                    text: '2020-Q4',
                },
                {
                    text: '2020',
                },
                {
                    text: '2021-Q1',
                },
                {
                    text: '2021-Q2',
                },
                {
                    text: '2021-Q3',
                },
                {
                    text: '2021-Q4',
                },
                {
                    text: '2021',
                },
                {
                    text: '2022-Q1',
                },
                {
                    text: '2022-Q2',
                },
                {
                    text: '2022-Q3',
                },
                {
                    text: '2022-Q4',
                },
                {
                    text: '2022',
                },
                {
                    text: '2023-Q1',
                },
                {
                    text: '2023-Q2',
                },
                {
                    text: '2023-Q3',
                },
                {
                    text: '2023-Q4',
                },
                {
                    text: '2023',
                },
                {
                    text: '2024-Q1',
                },
                {
                    text: '2024-Q2',
                },
                {
                    text: '2024-Q3',
                },
                {
                    text: '2024-Q4',
                },
                {
                    text: '2024',
                },
            ],
            selectedPeriod: '2023-Q2',
            selectedDate: '',
            videoLink: '',
            uploadedBase64EncodedFile: '',
            uploadedBase64EncodedFile2: '',
            uploadedBase64EncodedFilePdfUpdate: '',
            uploadedBase64EncodedFileSlideUpdate: '',
            fileType: '',
            test_reports: [
                {
                    company_id: 5470600,
                    date_report: '2023-02-21',
                    period: '2023',
                    type_report: 'FY',
                    pdf_link: '1f1f8948-6f5e-45e6-b6a0-0f8a7873a6c5',
                    slides_link: '1f1f8948-6f5e-45e6-b6a0-0f8a7873a6c5',
                    video_link: 'https://www.youtube.com/watch?v=Kw-Q_N7W9FI',
                },
            ],
            ir_reports: [
                {
                    company_id: 5470600,
                    period: '2023-Q3',
                    report_date: '2023-10-25',
                    report_type: 'Q',
                    pdf_file: 'katalysen-2023-Q3.pdf',
                    slides_link: 'katalysen-2023-Q3.pptx',
                    video_link: 'https://www.youtube.com/watch?v=1GNsWa_EZdw',
                },
            ],
            reportUpdate: {},
            reportUpdateCopy: {},
        };
    },
    created() {
        this.selectedDate = moment().format('YYYY-MM-DD');

        this.companies = JSON.parse(JSON.stringify(this.getCompanies));
        this.companiesCopy = JSON.parse(JSON.stringify(this.getCompanies));

        console.log(JSON.parse(JSON.stringify(this.companies)));
    },
    mounted() {
        if(!this.getLoggedIn) {
            window.location.href = '/backoffice/login';
        }

        console.log(JSON.parse(JSON.stringify(this.reports)));
        // console.log(JSON.parse(JSON.stringify(this.getReportsOnYear('2022'))));
        // console.log(this.years);
        // console.log(JSON.parse(JSON.stringify(this.mergedReports)));
    },
    computed: {
        ...mapGetters(['getLoggedIn', 'getCompany', 'getCompanies', 'getCompanyName']),
        reports() {
            const sortedReportsOnPeriod =  _.orderBy(this.getCompany.reports, ['date_report', 'period'], ['desc', 'asc']);

            return sortedReportsOnPeriod;
        },
        years() {
            let yearsTemp = [];

            for(const item of this.reports) {
                yearsTemp.push(this.getYear(item.period));
            }

            yearsTemp = _.uniq(yearsTemp);
            yearsTemp = yearsTemp.sort().reverse();

            return yearsTemp;
        },
        reportUpdateString() {
            return JSON.stringify(this.reportUpdate);
        },
        reportUpdateCopyString() {
            return JSON.stringify(this.reportUpdateCopy);
        },
        hostAddress() {
            return `${window.location.protocol}//${window.location.host}`;
        },
    },
    methods: {
        ...mapActions(['uploadMediaFile', 'openFile', 'updateMediaFile']),
        ...mapMutations(['setShowSnackbar']),
        downloadFile(link, file_name) {
            const element = document.createElement('a');

            element.setAttribute('href', link);

            if(file_name.split('.')[1] !== 'pdf') {
                element.setAttribute('download', file_name);
            }

            element.setAttribute('target', '_blank');

            element.style.display = 'none';

            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        },
        async watchOrDownloadFile(file_name) {
            const openFileObject = {
                short_name: this.getCompanyName,
                file_name: file_name,
            };

            const base64string = await this.openFile(openFileObject);
            const base64 = (base64string);

            let blob;

            if(file_name.split('.')[1] === 'pdf') {
                blob = this.base64ToBlob(base64, 'application/pdf');
            } else {
                blob = this.base64ToBlob(base64);
            }

            const url = URL.createObjectURL(blob);

            this.downloadFile(url, file_name);
        },
        base64ToBlob( base64, type = 'application/octet-stream' ) {
            const binStr = atob( base64 );
            const len = binStr.length;
            const arr = new Uint8Array(len);

            for (let i = 0; i < len; i++) {
                arr[i] = binStr.charCodeAt(i);
            }

            return new Blob([arr], {type: type});
        },
        cancel() {
            this.insertReportActive = false;
            this.fileType = '';
            this.uploadedBase64EncodedFile = '';
            this.uploadedBase64EncodedFile2 = '';
            this.uploadedBase64EncodedFilePdfUpdate = '';
            this.uploadedBase64EncodedFileSlideUpdate = '';
        },
        cancel2() {
            this.updateReportActive = false;
            this.fileType = '';
            this.uploadedBase64EncodedFile = '';
            this.uploadedBase64EncodedFile2 = '';
            this.uploadedBase64EncodedFilePdfUpdate = '';
            this.uploadedBase64EncodedFileSlideUpdate = '';
        },
        uploadFile() {
            const element = this.$refs.fileUploadElement;

            console.log(element);

            element.click();
        },
        uploadFile2() {
            const element = this.$refs.slideUploadElement;

            console.log(element);

            element.click();
        },
        uploadFileUpdate() {
            const element = this.$refs.fileUploadUpdateElement;

            console.log(element);

            element.click();
        },
        uploadSlideUpdate() {
            const element = this.$refs.slideUploadUpdateElement;

            console.log(element);

            element.click();
        },
        getFileData(e) {
            const fileInput = e.target;
            const file = fileInput.files[0];

            console.log('file', file);

            const reader = new FileReader();

            reader.addEventListener('load', () => {
                console.log('reader.result', reader.result);

                this.uploadedBase64EncodedFile = reader.result;
            });

            reader.readAsDataURL(file);
        },
        getFileData2(e) {
            const fileInput = e.target;
            const file = fileInput.files[0];
            this.fileType = fileInput.files[0].name.split('.')[1];

            console.log('file', file);
            console.log(fileInput.files);
            console.log(this.fileType);

            const reader = new FileReader();

            reader.addEventListener('load', () => {
                console.log('reader.result', reader.result);

                this.uploadedBase64EncodedFile2 = reader.result;
            });

            reader.readAsDataURL(file);
        },
        getFileDataPdfUpdate(e) {
            const fileInput = e.target;
            const file = fileInput.files[0];
            // this.fileType = fileInput.files[0].name.split('.')[1];

            console.log('file', file);
            console.log(fileInput.files);
            // console.log(this.fileType);

            const reader = new FileReader();

            reader.addEventListener('load', () => {
                console.log('reader.result', reader.result);

                this.uploadedBase64EncodedFilePdfUpdate = reader.result;
            });

            reader.readAsDataURL(file);
        },
        getFileDataSlideUpdate(e) {
            const fileInput = e.target;
            const file = fileInput.files[0];
            this.fileType = fileInput.files[0].name.split('.')[1];

            console.log('file', file);
            console.log(fileInput.files);
            console.log(this.fileType);

            const reader = new FileReader();

            reader.addEventListener('load', () => {
                console.log('reader.result', reader.result);

                this.uploadedBase64EncodedFileSlideUpdate = reader.result;
            });

            reader.readAsDataURL(file);
        },
        async saveFile() {
            const company_id = this.getCompany.company_id;
            const companyName = this.getCompanyName;
            const period = this.selectedPeriod;
            // const title = period.length > 4 ? `Interim Report ${period}` : `Full Year Report ${period}`;
            const reportDate = this.selectedDate;
            const videoLink = this.videoLink;
            const type = period.length > 4 ? 'Q' : 'FY';
            const rawBase64EncodedFile = this.uploadedBase64EncodedFile.split(',')[1] !== undefined ? this.uploadedBase64EncodedFile.split(',')[1] : '';
            const rawBase64EncodedFile2 = this.uploadedBase64EncodedFile2.split(',')[1] !== undefined ? this.uploadedBase64EncodedFile2.split(',')[1] : '';

            const insertObject = {
                company_id: company_id,
                period: period,
                // title: title,
                date_report: reportDate,
                type_report: type,
                pdf_link_local: rawBase64EncodedFile !== '' ? `pdf_${companyName}-${period}.pdf` : '',
                slides_link_local: rawBase64EncodedFile2 !== '' ? `slide_${companyName}-${period}.${this.fileType}` : '',
                video_link: videoLink,
                // pdf_language: 'sv, en, de, ru...', --> This is not to be added yet
                pdf_encoding: rawBase64EncodedFile,
                slides_encoding: rawBase64EncodedFile2,
            };

            console.log('insertObject', insertObject);

            const result = await this.uploadMediaFile(insertObject);

            console.log(result);

            if(result.status === 0) {
                this.setShowSnackbar({
                    show: true,
                    message: 'Successfully inserted report',
                    type: 'success',
                });
            } else {
                this.setShowSnackbar({
                    show: true,
                    message: result.message,
                    type: 'error',
                });
            }

            this.companies = JSON.parse(JSON.stringify(this.getCompanies));
            this.companiesCopy = JSON.parse(JSON.stringify(this.getCompanies));

            this.insertReportActive = false;
            this.selectedPeriod = '2023-Q2';
            this.selectedDate = moment().format('YYYY-MM-DD');
            this.fileType = '';
            this.uploadedBase64EncodedFile = '';
            this.uploadedBase64EncodedFile2 = '';
            this.uploadedBase64EncodedFilePdfUpdate = '';
            this.uploadedBase64EncodedFileSlideUpdate = '';
        },
        async updateFile() {
            const company_id = this.reportUpdate.company_id;
            const companyName = this.getCompanyName;
            const period = this.reportUpdate.period;
            // const title = period.length > 4 ? `Interim Report ${period}` : `Full Year Report ${period}`;
            const reportDate = this.reportUpdate.date_report;
            const videoLink = this.reportUpdate.video_link;
            const type = period.length > 4 ? 'Q' : 'FY';
            const rawBase64EncodedPdfFile = this.uploadedBase64EncodedFilePdfUpdate.split(',')[1] !== undefined ? this.uploadedBase64EncodedFilePdfUpdate.split(',')[1] : '';
            const rawBase64EncodedSlideFile = this.uploadedBase64EncodedFileSlideUpdate.split(',')[1] !== undefined ? this.uploadedBase64EncodedFileSlideUpdate.split(',')[1] : '';
            const usedFileType = this.reportUpdateCopy.slides_link_local.split('.')[1];

            const updateObject = {
                company_id: company_id,
                period: period,
                // title: title,
                date_report: reportDate,
                type_report: type,
                pdf_link_local: `pdf_${companyName}-${period}.pdf`,
                slides_link_local: this.fileType !== '' ? `slide_${companyName}-${period}.${this.fileType}` : `slide_${companyName}-${period}.${usedFileType}`,
                video_link: videoLink,
                // pdf_language: 'sv, en, de, ru...', --> This is not to be added yet
                pdf_encoding: rawBase64EncodedPdfFile,
                slides_encoding: rawBase64EncodedSlideFile,
            };

            if(rawBase64EncodedPdfFile !== '') {
                updateObject.pdf_encoding = rawBase64EncodedPdfFile;
            }

            if(rawBase64EncodedSlideFile !== '') {
                updateObject.slides_encoding = rawBase64EncodedSlideFile;
            }

            console.log('updateObject', updateObject);

            const result = await this.updateMediaFile(updateObject);

            console.log(result);

            if(result.status === 0) {
                this.setShowSnackbar({
                    show: true,
                    message: 'Successfully updated report',
                    type: 'success',
                });
            } else {
                this.setShowSnackbar({
                    show: true,
                    message: result.message,
                    type: 'error',
                });
            }

            this.companies = JSON.parse(JSON.stringify(this.getCompanies));
            this.companiesCopy = JSON.parse(JSON.stringify(this.getCompanies));

            this.updateReportActive = false;
            this.uploadedBase64EncodedFilePdfUpdate = '';
            this.uploadedBase64EncodedFileSlideUpdate = '';
        },
        openInsertReportPopup() {
            this.fileType = '';
            this.videoLink = '';
            this.insertReportActive = true;
            this.updateReportActive = false;
        },
        openInsertReportPopup2(report) {
            this.fileType = '';
            this.videoLink = '';
            this.updateReportActive = true;
            this.insertReportActive = false;

            this.reportUpdate = JSON.parse(JSON.stringify(report));
            this.reportUpdateCopy = JSON.parse(JSON.stringify(report));

            console.log(JSON.parse(JSON.stringify(this.reportUpdate)));
        },
        getTypeText(report) {
            if(report.report_type !== undefined) {
                return `${this.type.find((t) => t.type === report.report_type).text} ${report.period}`;
            } else {
                return `${this.type.find((t) => t.type === report.type_report).text} ${report.period}`;
            }
        },
        getYear(stringValue) {
            return Number(stringValue.substring(0, 4));
        },
        getReportsOnYear(year) {
            const yearTemp = Number(year);
            let reportsTemp = [];

            for(const report of this.reports) {
                if(this.getYear(report.period) === yearTemp) {
                    reportsTemp.push(report);
                }
            }

            reportsTemp = _.orderBy(reportsTemp, ['date_report'], ['desc']);

            return reportsTemp;
        },
    },
}
</script>

<style scoped>
>>> .v-text-field.v-text-field--enclosed .v-text-field__details,
>>> .v-text-field.v-text-field--enclosed > .v-input__control > .v-input__slot {
    margin: 0;
    padding-bottom: 4px;
    padding-top: 2px;
    max-height: 20px;
    min-height: 20px;
}
</style>
