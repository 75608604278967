<template lang="pug">
    v-app(id="app" class="ir-base-app")
        Main(v-if="finishedLoading || backOfficeCheck")
        MainSkeleton(v-else)

        SmokeBar
</template>

<script>
import Main from "@/views/Main.vue";
import MainSkeleton from "@/views/MainSkeleton.vue";
import SmokeBar from "@/components/backoffice/helpers/SmokeBar.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import axios from "axios";
import i18n from './plugins/i18n';

export default {
    name: "App",
    components: {
        Main,
        MainSkeleton,
        SmokeBar,
    },
    data() {
        return {
            finishedLoading: false,
            selectedLanguageCode: null,
        };
    },
    async mounted() {
        console.log(!this.backOfficeCheck);

        if(!this.backOfficeCheck) {
            // await this.loadCompanyCanonicalCName({ url: 'ir.katalysen.com' });
            await this.loadCompanyCanonicalCName({ url: window.location.host });

            // console.log(this.getCompanyCanonicalCName);
            // console.log(this.getCompanyCanonicalCName.cnames);

            let tempName = this.getCompanyCanonicalCName;

            console.log(tempName);

            this.setCompanyName(tempName);

            //const languageItem = (await axios.get("https://geolocation-db.com/json/")).data;  // service stopped working
            let countryCode = 'SE'; //languageItem.country_code;

            console.log('*********// your location (hard coded) //*************', countryCode);
            console.log(localStorage.getItem('language'));

            if(localStorage.getItem('language') === null) {
                if(countryCode === 'SE') {
                    countryCode = 'sv';
                } else {
                    countryCode = 'en';
                }

                localStorage.setItem('language', countryCode);

                // if(tempName === "katalysen") {
                //     localStorage.setItem('language', 'en');
                // } else {
                //     localStorage.setItem('language', countryCode);
                // }
            }

            console.log(localStorage.getItem('language'));

            this.selectedLanguageCode = localStorage.getItem('language');

            console.log(this.selectedLanguageCode);

            i18n.locale = this.selectedLanguageCode;

            this.setLanguageCode(this.selectedLanguageCode);

            console.log('test block ****');
            console.log('test block ****');
            console.log('test block ****');
            console.log('test block ****');

            console.time('loadCompany');
            await this.loadCompany({
                short_name: tempName,
                language: this.selectedLanguageCode,
            });
            console.timeEnd('loadCompany');

            console.log(JSON.parse(JSON.stringify(this.getCompany)));

            this.setIsListed(this.getCompany.is_listed);
            const company_id = this.getCompany.company_id;
            console.log('company_id:', company_id);

            if(company_id !== undefined) {
                document.title = this.getCompany.browser_tab_text;

                let link = document.querySelector("link[rel~='icon']");

                if(!link) {
                    link = document.createElement('link');
                    link.rel = 'icon';
                    document.head.appendChild(link);
                }

                link.href = this.getCompany.favicon;

                let backgroundColorTemp = this.getCompany.css_styles.length > 0 ? this.getCompany.css_styles
                            .split('.ir-menu-drawer-active-regular {')[1]
                            .split('background-color: ')[1]
                            .split('!')[0]
                            .trim() : '';

                console.log(backgroundColorTemp.length);
                console.log(backgroundColorTemp);

                backgroundColorTemp = backgroundColorTemp.length === 7 ? backgroundColorTemp : '#000000';

                console.log(backgroundColorTemp);

                this.setCompanyColor(backgroundColorTemp);

                console.log(this.getCompanyColor);

                this.addStyle(this.getCompany.css_styles);

                if ( this.getCompany.is_listed) {
                    console.log('entered...');
                    console.log('entered...');
                    console.log('entered...');
                    console.log('entered...');
                    console.time('loadCompanyPrices');
                    await this.loadCompanyPrices(company_id);
                    console.timeEnd('loadCompanyPrices');
                }

                this.finishedLoading = true;

                console.time('loadCompanyDetails');
                await this.loadCompanyDetails();
                console.timeEnd('loadCompanyDetails');

                console.log('getCompany');
                console.log('getCompany');
                console.log('getCompany');
                console.log('getCompany');
                console.log(JSON.parse(JSON.stringify(this.getCompany)));
            }
        }
    },
    computed: {
        ...mapGetters(['getCompany', 'getCompanyCanonicalCName', 'getCompanyPrices', 'getCompanyColor']),
        backOfficeCheck() {
            return window.location.href.split('/').includes('backoffice');
        },
    },
    methods: {
        ...mapActions(['loadCompany', 'loadCompanyCanonicalCName', 'loadCompanyPrices', 'loadCompanyDetails']),
        ...mapMutations(['setCompanyName', 'setLanguageCode', 'setCompanyColor', 'setCompany', 'setIsListed']),
        addStyle(styleString) {
            const style = document.createElement('style');

            style.textContent = styleString;

            document.head.append(style);
        },
        findBetween(text, start, end) {
            let startIndex = text.indexOf(start);
            let endIndex = text.lastIndexOf(end);

            if (startIndex === -1) {
                throw new Error("Start string not found.");
            } else if (endIndex === -1) {
                throw new Error("End string not found.");
            }

            startIndex += start.length;

            if (startIndex > endIndex) {
                throw new Error("Start found after end!");
            }

            // remove block
            // return text.replace(`${start}${text.substring(startIndex, endIndex)}${end}`, '');

            // get block
            return `${start}${text.substring(startIndex, endIndex)}${end}`;
        },
        removeBetween(text, subtext) {
            let textTemp = text;

            textTemp = textTemp.replace(/^\s*[\r\n]/gm, '');
            textTemp = textTemp.replace(subtext, '');

            return textTemp;
        },
        removeBlankRowsInText(text) {
            return text.replace(/^\s*[\r\n]/gm, '');
        },
    },
}
</script>
